import { render, staticRenderFns } from "./RegistrAtion.vue?vue&type=template&id=721c5bec&scoped=true"
import script from "./RegistrAtion.vue?vue&type=script&lang=js"
export * from "./RegistrAtion.vue?vue&type=script&lang=js"
import style0 from "./RegistrAtion.vue?vue&type=style&index=0&id=721c5bec&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "721c5bec",
  null
  
)

export default component.exports